import React, { useContext, useEffect } from 'react'
import Checkout from '../components/checkout'
import { KEYS } from '../utils/constant'
import { SessionStorage } from '../utils/storage'
import { useNavigate } from 'react-router-dom'
import { route } from '../utils/route'
import AppContext from "../utils/appContext";

const CheckoutIndex = ({
  searchPanelData,
  siteConfig,
  authToken
}) => {
  let navigate = useNavigate()
	const context = useContext(AppContext);

  useEffect(() => {
   context.setAddedCode(false)
   const roomData = SessionStorage.getJSON(KEYS.ROOM_DATA)
   if(!roomData?.[0]?.room_info?.room_id) {
      navigate(route.roomList)      
   }
    setTimeout(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }, 100)
  }, [])

  return (
    <Checkout
      searchPanelData={searchPanelData}
      siteConfig={siteConfig}
      authToken={authToken}
    />
  )
}

export default CheckoutIndex