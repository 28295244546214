import React, { useEffect, useState, useContext } from 'react'
import { KEYS, NUMBER_REGEX } from '../../utils/constant'
import { SessionStorage } from '../../utils/storage'
import { TranslatedPrefix, alphabeticName, replaceEmoji } from '../../utils/utils'
import Checkbox from '../common/Checkbox'
import Input from '../common/Input'
import PhoneInput from '../common/Input/phoneInput'
import Select from '../common/Select'
import GuestDetails from './guestDetails'
import AppContext from '../../utils/appContext'
import { useTranslation } from "react-i18next"
import { PREFIX } from '../../utils/utils'

const ContactDetails = ({
    activeTab,
    // activeTabGuest,
    handleTabClick,
    stopClose,
    // handleTabClickGuest,
    register,
    errors,
    setValue,
    getValues,
    control,
    watch,
    clearErrors,
    trigger,
    resetField,
    addonData,
    bookingData,
    checkoutData,
}) => {
	const { t } = useTranslation();
	const [checkboxIndex, setCheckBoxIndex] = useState();
	let context = useContext(AppContext);

	const updateData = () => {
		const roomData = SessionStorage.getJSON(KEYS.ROOM_DATA);
		if (bookingData?.guest_details?.[0]?.iamstay === 1) {
			setCheckBoxIndex(0);
		}
		const newData = roomData?.map((detail, index) => ({
			prefix:
				index === 0
					? bookingData?.guest_details?.[0]?.title
					: undefined,
			firstName:
				index === 0
					? bookingData?.guest_details?.[0]?.firstName
					: undefined,
			lastName:
				index === 0
					? bookingData?.guest_details?.[0]?.lastName
					: undefined,
			arrivalTime: undefined,
			stayInRoom:
				index === 0 && bookingData?.guest_details?.[0]?.iamstay === 1
					? true
					: false,
			additional: undefined,
			...detail,
			addonData: addonData?.map((add) => ({
				...add,
				count:
					detail.enhancement?.find((enh) => enh.id === add.id)
						?.count || 0,
			})),
		}));
		setValue("guest", newData);
	};

	useEffect(updateData, [addonData]);

	useEffect(() => {
		if (context.enhancementChange) {
			updateData();
			context.setEnhancementChange(false);
		}
	}, [context.enhancementChange]);

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			handleTabClick(0);
		}
	};

	let language = localStorage.getItem("i18nextLng")
		? localStorage.getItem("i18nextLng")
		: "en";

	const TRANS_PREFIX = TranslatedPrefix(PREFIX, language);

	return (
		<div
			className={`checkoutTab ${
				activeTab === 0 ? "checkoutTabShow" : ""
			}`}
			tabIndex='0'
			onClick={(e) => handleTabClick(0)}
			onKeyPress={(e) => handleKeyPress(e)}
		>
			<h2 className='textUppercase'>{t("contact_details")}</h2>
			<div className='checkoutTabDetails' onClick={(e) => stopClose(e)}>
				<div className='columnWrap'>
					<div className='column33 marginB25 mobileTwoColumn'>
						<label className='inputFieldWrap'>
							{/* <span className='hideTag' aria-lablledby='Title'>Title</span> */}
							{/* <GenderSelect setValue={setValue} labelText="prefix" /> */}
							<Select
								title='Title'
								dropdown={TRANS_PREFIX}
								rest={register("prefix")}
								defaultValue={"Title"}
								error={errors.prefix?.message}
							/>
						</label>
					</div>
					<div className='column33 marginB25 mobileTwoColumn'>
						<label className='inputFieldWrap'>
							{/* <span className='hideTag' aria-lablledby={t("first_name")}>{t("first_name")}</span> */}
							<Input
								title={t("first_name")}
								placeholder={t("first_name")}
								required
								maxLength='20'
								rest={register("firstName", {
									onChange: (e) => {
										setValue(
											"firstName",
											alphabeticName(e.target.value)
										);
									},
								})}
								error={errors.firstName?.message}
							/>
						</label>
					</div>
					<div className='column33 marginB25'>
						<label className='inputFieldWrap'>
							{/* <span className='hideTag' aria-lablledby={t("last_name")}>{t("last_name")}</span> */}
							<Input
								title={t("last_name")}
								placeholder={t("last_name")}
								required
								maxLength='20'
								rest={register("lastName", {
									onChange: (e) => {
										setValue(
											"lastName",
											alphabeticName(e.target.value)
										);
									},
								})}
								error={errors.lastName?.message}
							/>
						</label>
					</div>
					<div className='column33 marginB25 phoneNumber'>
						<label className='inputFieldWrap' for='phoneNum1'>
							{/* <span className='hideTag' aria-lablledby={t("phone_number")}>{t("phone_number")}</span> */}
							<PhoneInput
								title={t("phone_number")}
								required
								placeholder={t("phone_number")}
								maxLength='15'
								rest={register("phoneNumber", {
									onChange: (event) => {
										setValue(
											"phoneNumber",
											event.target.value.replace(
												NUMBER_REGEX,
												""
											)
										);
									},
								})}
								codeRest={register("phoneCode")}
								error={errors.phoneNumber?.message}
								phoneCodeError={errors.phoneCode?.message}
								bookingData={bookingData}
								watch={watch}
								setValue={setValue}
							/>
						</label>
					</div>
					<div className='column33 marginB25'>
						<label className='inputFieldWrap'>
							{/* <span className='hideTag' aria-lablledby={t("email")}>{t("email")}</span> */}
							<Input
								title={t("email")}
								type='email'
								placeholder={t("email")}
								required
								maxLength='50'
								rest={register("email", {
									onChange: (event) => {
										setValue(
											"email",
											replaceEmoji(event.target.value)
										);
									},
									onBlur: () => {
										if (watch("confirmEmail")) {
											trigger("confirmEmail");
										}
									},
								})}
								error={errors.email?.message}
							/>
						</label>
					</div>
					<div className='column33 marginB25'>
						<label className='inputFieldWrap'>
							{/* <span className='hideTag' aria-lablledby={t("confirm_email")}>
								{t("confirm_email")}
							</span> */}
							<Input
								title={t("confirm_email")}
								type='email'
								placeholder={t("confirm_email")}
								required
								maxLength='50'
								rest={register("confirmEmail", {
									onChange: (event) => {
										setValue(
											"confirmEmail",
											replaceEmoji(event.target.value)
										);
									},
								})}
								error={errors.confirmEmail?.message}
							/>
						</label>
					</div>
					<div className='column100 customCheckBox'>
						<Checkbox
							id='optIn'
							text={t("opt_in").replace("[hotelname]", checkoutData?.hotel?.hotel_name)}
							isChecked={watch("optIn")}
							rest={register("optIn", {
								onChange: (event) => {
									setValue("optIn", event.target.checked);
								},
							})}
						/>
					</div>
				</div>
				{getValues("guest")?.length ? (
					<GuestDetails
						control={control}
						setValue={setValue}
						errors={errors}
						// activeTabGuest={activeTabGuest}
						stopClose={stopClose}
						// handleTabClickGuest={handleTabClickGuest}
						register={register}
						watch={watch}
						checkboxIndex={checkboxIndex}
						setCheckBoxIndex={setCheckBoxIndex}
						clearErrors={clearErrors}
						checkoutData={checkoutData}
						trigger={trigger}
					/>
				) : (
					""
				)}
			</div>
		</div>
	);
}

export default ContactDetails
