/**
 * @description URL Constant class with all APIs urls declaration
 * @memberof UrlConstants.
 */
class UrlConstants {
  constructor() {
    //Application Site URL
    //this.SiteUrl = process.env.REACT_APP_SITE_URL + "/#";
    this.protocol = `${window.location.protocol}//`;
    this.host = this.protocol + window.location.host;
    this.siteUrl = window.location.hostname;
    this.imageUrl = "https://resources.be.bookingdirect.com/public/images";
    this.BuildVersion = `build-2.0.0`;

    if(this.siteUrl.includes('ibev3-build-dev.webbuild')){
      this.siteUrl = this.siteUrl.replace("ibev3-build-dev.webbuild", "ibev3-dev.webbuild");
    }
    if(this.siteUrl.includes('ibev3-dev.webbuild')){
      this.siteUrl = this.siteUrl.replace("dev", "qa");
    }

    if(this.siteUrl.includes('r18ibev4-dev')){
      this.siteUrl = this.siteUrl.replace("dev", "qa");
    }

    if(this.siteUrl.includes('r18ibev4-secref')){
      this.siteUrl = this.siteUrl.replace("secref", "qa");
    }

    if(this.siteUrl.includes('luxury') || this.siteUrl.includes('localhost') || this.siteUrl.includes('161.35.71.11')){
      this.siteUrl = "claridges-r18ibev4-qa.webbuild.hotelierideas.com";
    }

    if(this.siteUrl.includes('kuldeep')){
      this.siteUrl = this.siteUrl.replace("kuldeep", "qa");
    }

    if(this.siteUrl.includes('vikas')){
      this.siteUrl = this.siteUrl.replace("vikas", "qa");
    }

    if(this.siteUrl.includes('anurag')){
      this.siteUrl = this.siteUrl.replace("anurag", "qa");
    }

    if(this.siteUrl.includes('dharmesh')){
      this.siteUrl = this.siteUrl.replace("dharmesh", "qa");
    }
    let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)checkv10\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    let sitebaseURL;
    if(cookieValue == 1){
      sitebaseURL = "https://bd-apiv4gw-uat.apibuild.hotelierideas.com/";
    }else{
      sitebaseURL = "https://bd-apiv4gw-uat.apibuild.hotelierideas.com/";
      
    }
    sitebaseURL = "https://gwbe4.bdapiprod.hotelieridea.com/";
    /* API Host URL */
    this.Host = sitebaseURL
    
    this.authUrl = `${this.Host}/authenticate`;
    this.configUrl = `${this.Host}/getconfig`;
    this.maintenanceUrl = `${this.Host}/sitedowntime`;
    this.hotelListUrl = `${this.Host}/searchpaneldata`;
    this.currencyUrl = `${this.Host}/getCurrency`;
    this.hotelDeatilUrl = `${this.Host}/getsingleroom`;
    this.checkoutUrl = `${this.Host}/checkout`;
    this.offerPageUrl = `${this.Host}/getofferpage`;
    this.policyUrl = `${this.Host}/fetchpolicy`;
    this.resourceUrl = "https://resources.be.bookingdirect.com/public";
    this.saveReservationUrl = `${this.Host}/savereservation`;
    this.manageBookingUrl = `${this.Host}/managebooking`;
    this.fetchReservationUrl = `${this.Host}/fetchbookingdetails`;
    this.countryListUrl = `${this.Host}/getCountry`;
    this.sendinquiry = `${this.Host}/sendinquiryemail`;
    this.modifyReservationUrl = `${this.Host}/modifyreservation`;
    this.cancelRequest = `${this.Host}/cancelreservation`;
    this.roomUpgrade = `${this.Host}/roomupgrade`;
    this.hotelMetaDetails = `${this.Host}/hotelmetadetail`;
    this.sendresemail = `${this.Host}/sendreservationemail`;
    this.getmodificationdetails = `${this.Host}/getmodificationdetails`;
    this.extIconURL = "https://ext-resources.hotelieridea.com/ibe-ext-static/UserFiles";
    //this.siteUrl = "rvng-ibe-uat.build.pegswebservices.com";
    //this.siteUrl = "maybourne.pegswebservices.com";
    //this.hoteId = "138685";
  }
}
export default new UrlConstants();
