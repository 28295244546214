import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import TextField from "@mui/material/TextField"
import Moment from "moment"
import dayjs from 'dayjs'
import { KEYS } from '../../../utils/constant'
import { useTranslation } from "react-i18next"
import { SessionStorage } from '../../../utils/storage'
import { useNavigate } from 'react-router-dom'
import { route } from '../../../utils/route'
import DateRangeBooking from '../../manageBooking/dateRangeBooking'
import RoomUpdate from '../../manageBooking/roomUpdate'
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import 'dayjs/locale/fr';
import MaxOccupancyError from './maxOccupancyError'
import { MAX_DATE, getMaxCount, getMinDate,seasonalClosureCheck } from '../../../utils/utils'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    maxWidth: 1000,
    minWidth: 1000,
    p: 0,
    bgcolor: "#fff",
    textAlign: "left",
}

const ModifyBookingModal = ({
    open,
    setOpen,
    bookingData,
    roomDetail,
    siteConfig
}) => {
   const navigate = useNavigate()
   const { t } = useTranslation()
   const [value, setValue] = useState([dayjs(bookingData?.date_in), dayjs(bookingData?.date_out)])
   const [adultCount, setAdultCount] = useState(1)
   const [childCount, setChildCount] = useState(0)
   const [showGuests, setShowGuests] = useState(false)
   const [diplayMaxOccError, setdisplayMaxOccError] = useState(0);

   useEffect(() => {
    if (open) {
      document.body.classList.add('bodyScrollHidden');
    } else {
      document.body.classList.remove('bodyScrollHidden');
    }
    return () => {
      document.body.classList.remove('bodyScrollHidden');
    };
  }, [open])

   useEffect(() => {
    setValue([dayjs(bookingData?.date_in), dayjs(bookingData?.date_out)])
  }, [bookingData?.date_in])

  useEffect(() => {
    setAdultCount(roomDetail?.adults || 1)
  }, [roomDetail?.adults])

  useEffect(() => {
    setChildCount(roomDetail?.children || 0)
  }, [roomDetail?.children])

  useEffect(() => {
    if((adultCount + childCount) > getMaxCount(siteConfig?.site_id)){
      setdisplayMaxOccError(1);
    }else{
      setdisplayMaxOccError(0);
    }
  }, [adultCount, childCount])

  const handleClose = () => {
    setOpen(false)
    setShowGuests(false)
    setValue([dayjs(bookingData?.date_in), dayjs(bookingData?.date_out)])
    setAdultCount(roomDetail?.adults || 1)
    setChildCount(roomDetail?.children || 0)
  }
 
  const modifyStay = () => {
    let newData = [{ 
        room: 1,
        adults: adultCount,
        childrens: childCount > 0 ? Array.from({length: childCount}, (_, i) => i) : [],
        price: [],
        room_info: {
            room_id:0,
            rate_id:0,
            room_name: "",
            rate_name:""
        }
    }]
    SessionStorage.setJSON(KEYS.ROOM_DATA, newData)
    SessionStorage.set(KEYS.CHECK_IN_DATE, dayjs(value[0]).format("YYYY-MM-DD"))
    SessionStorage.set(KEYS.CHECKOUT_DATE, dayjs(value[1]).format("YYYY-MM-DD"))
    SessionStorage.set("is_modification", 1)
    navigate(route.roomList)
  }

  const openGuests = () => {
    setShowGuests(true)
  }

  let language = localStorage.getItem("i18nextLng")
		? localStorage.getItem("i18nextLng")
		: "en";

  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="commonModalPopup modifyBookingPopup"
        role="dialog"
			  aria-modal="true"
    >
        <Box sx={style}>
            <div className="white-popup-block p0"> 
                <div className="modalTitleWrap">        
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t("modify_your_stay")}
                        <button aria-label='Close Button' type="button" className="mfp-close" onClick={() => handleClose()}>Close</button>
                    </Typography>
                </div> 
                <div className="modifyPopupDateWrap">
                    <div className="modifyPopupDateWrapMobile">
                        {showGuests ? 
                            <div className="column35 modifyPopupRoomCount">
                                <RoomUpdate
                                    adults={roomDetail?.adults || 1}
                                    children={roomDetail?.children || 0}
                                    setAdultCount={setAdultCount}
                                    setChildCount={setChildCount}
                                    siteConfig={siteConfig}
                                />
                                <MaxOccupancyError diplayMaxOccError={diplayMaxOccError} />
                                <div className="modifyStayBtnWrap marginT30">
                                    <button
                                      className="customBtn customBtnDark w100"
                                      type="button"
                                      onClick={() => modifyStay()}
                                    >{t("updatesearch")}</button>
                                </div>
                            </div> : 
                        <DateRangeBooking
                            checkInDate={bookingData?.date_in}
                            checkOutDate={bookingData?.date_out}
                            openGuests={openGuests}
                            onChangeDate={newValue => setValue(newValue)}
                        />
                        }
                    </div>
                    <div className="columnWrap modifyPopupDateWrapDesktop">
                        <div className="column65 modifyPopupDateBorder">
                            <div className="modifyPopupDate">
                                <LocalizationProvider adapterLocale={language} dateAdapter={AdapterDayjs}>
                                    <StaticDateRangePicker
                                        disablePast={true}
                                        minDate={getMinDate(bookingData?.hotel_detail?.check_in_min_date)}
                                        maxDate={MAX_DATE}
                                        displayStaticWrapperAs="desktop"
                                        label={t("date_range")}
                                        value={value}
                                        dayOfWeekFormatter={days=>{
                                            return days.toUpperCase()
                                        }}
                                        inputFormat="YYYY-MM-DD"
                                        shouldDisableDate={(day)=>seasonalClosureCheck(day,siteConfig?.site_id)}
                                        onChange={newValue => {
                                        setValue(newValue)
                                        let newFormat = []
                                        let issamelastdate = false;
                                        if (newValue[0] != null) {
                                          issamelastdate = Moment(newValue[0].$d).isSame(MAX_DATE.$d);
                                          if(issamelastdate === true){
                                            newFormat[0] = dayjs(Moment(newValue[0].$d).subtract(1, "days").format(
                                              "yyyy-MM-DD"
                                            ));
                                          }else{
                                            newFormat[0] = dayjs(Moment(newValue[0].$d).format(
                                              "yyyy-MM-DD"
                                            ));  
                                          }
                                          }
                                          if (newValue[1] != null) {
                                            newFormat[1] = dayjs(Moment(newValue[1].$d).format(
                                              "yyyy-MM-DD"
                                            ));
                                          } else if (newValue[1] === null) {
                                            newFormat[1] = dayjs(Moment(newValue[0].$d)
                                              .add(1, "days")
                                              .format("yyyy-MM-DD"));
                    
                                            }
                                            if(issamelastdate === true){
                                              newFormat[1] = dayjs(Moment(newValue[0].$d).format("yyyy-MM-DD"));
                                            }
                                        setValue(newFormat)
                                        }}
                                        allowSameDateSelection={false}
                                        renderInput={(startProps, endProps) => (
                                            <React.Fragment>
                                                <TextField {...startProps} />
                                                <Box sx={{ mx: 2 }}> {t("to")} </Box>
                                                <TextField {...endProps} />
                                            </React.Fragment>
                                        )}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className="column35 modifyPopupRoomCount">
                            <h2 class="paddingB15 textUppercase">{t("room_1")}</h2>
                                <RoomUpdate
                                  adults={roomDetail?.adults || 1}
                                  children={roomDetail?.children || 0}
                                  setAdultCount={setAdultCount}
                                  setChildCount={setChildCount}
                                  siteConfig={siteConfig}
                                />
                                <MaxOccupancyError diplayMaxOccError={diplayMaxOccError} />
                        </div>
                    </div>
                </div>
                <div className="modifyStayBtnWrap textRight">
                    <button
                      className="customBtn customBtnDark"
                      type="button"
                      onClick={() => diplayMaxOccError === 1 ? false : modifyStay()}
                      disabled={diplayMaxOccError === 1}
                      style={{ cursor: diplayMaxOccError === 1 ? "unset" : "pointer" }}
                    >{t("modify_stay")}</button>
                </div>
            </div>
        </Box>
    </Modal>
  )
}

export default ModifyBookingModal