import React, { useState, useContext } from "react";
import dayjs from 'dayjs';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { LicenseInfo } from "@mui/x-license-pro";
import Moment from "moment";
import { useTranslation } from "react-i18next";
import 'dayjs/locale/fr';
import { MAX_DATE, getMinDate,seasonalClosureCheck } from "../../utils/utils";
import AppContext from "../../utils/appContext";
 
LicenseInfo.setLicenseKey(
  "cc9787baaa520f6ffc659927e45e260fTz00NjY0NSxFPTE2ODgyOTQyNDcyNDcsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const DateRangeCart = props => {
  const { t } = useTranslation();
  let context = useContext(AppContext);
  const [value, setValue] = useState([dayjs(props.checkindate), dayjs(props.checkoutdate)]);
  const [continueText, setContinueText] = useState(false)

  let openRoomEdit = () => {
    props.showRoomCountFunc();
    props.setshowSingleRoom(false);
    props.openRoomEdit(1);
    // sessionStorage.setItem("checkInDate", props.checkindate);
    // sessionStorage.setItem("checkoutDate", props.checkoutdate);
  };

  let language = localStorage.getItem("i18nextLng") || "en";

  return (
    <>
      <div class="cartSidebar dateRangeSideBar">
        <div class="cartRoomInfoSidebar">
          <div class="cartSidebarTop posRelative">
            <div class="columnWrap cartSidebarRow">
              <div class="column80 pL0">
                <h2 class="m0 paddingTB25 textUppercase">{t("dates")}</h2>
              </div>
              <div class="column20 textRight pR0 calenderPopupClose">
                <a
                  class="removeRoom closeIcon dInlineBlock"
                  onClick={() => props.openCalendar()}
                  aria-label='Close Button'
                ></a>
              </div>

              <div class="cartSidebarRow sidebarDate w100">
                <div class="columnWrap">
                  <div class="column50 pL0">
                    <small class="dBlock textUppercase">{t("check_in")}</small>
                    <span class="dBlock checkInDate bookingDate">
                      {Moment(props.checkindate).format("ddd, DD MMM YYYY")}
                    </span>
                  </div>
                  <div class="column50 pL0">
                    <small class="dBlock textUppercase">{t("check_out")}</small>
                    <span class="dBlock checkOutDate bookingDate">
                      {Moment(props.checkoutdate).format("ddd, DD MMM YYYY")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="sidebarDateWrap paddingB25">
              <div class="cartSidebarRow sidebarDate">
                <LocalizationProvider adapterLocale={language} dateAdapter={AdapterDayjs}>
                  <StaticDateRangePicker
                    disablePast={true}
                    maxDate={MAX_DATE}
                    minDate={getMinDate(context?.hotelData?.check_in_min_date)}
                    displayStaticWrapperAs="desktop"
                    label="date range"
                    value={value}
                    inputFormat="YYYY-MM-DD"
                    dayOfWeekFormatter={days=>{
                      return days.toUpperCase()
                    }}
                    shouldDisableDate={(day)=>seasonalClosureCheck(day,context?.configData?.site_id)}
                    onChange={newValue => {
                      setValue(newValue);
                      let newFormat = [];
                      let displayFormat = [];

                      let issamelastdate = false;
                      if (newValue[0] != null) {

                        issamelastdate = Moment(newValue[0].$d).isSame(MAX_DATE.$d);
                        if(issamelastdate === true){
                          newFormat[0] = dayjs(Moment(newValue[0].$d).subtract(1, "days").format("yyyy-MM-DD"));
                          displayFormat[0] = Moment(newValue[0].$d).subtract(1, "days").format("yyyy-MM-DD");
                        }else{
                          newFormat[0] = dayjs(Moment(newValue[0].$d).format("yyyy-MM-DD"));
                          displayFormat[0] = Moment(newValue[0].$d).format("yyyy-MM-DD");
                        }
                      }
                      if (newValue[0] === null) {
                        newValue[0] = new Date();
                        newFormat[0] = dayjs(Moment(newValue[0].$d).format("yyyy-MM-DD"));
                        displayFormat[0] = Moment(newValue[0].$d).format("yyyy-MM-DD");
                      }
                      if (newValue[1] != null) {
                      let issame = Moment(newValue[0].$d).isSame(newValue[1].$d);
                        if(issame === true){
                          newFormat[1] = dayjs(Moment(newValue[0].$d).add(1, "days").format("yyyy-MM-DD"));
                          displayFormat[1] = Moment(newValue[0].$d).add(1, "days").format("yyyy-MM-DD");
                        }else{
                          newFormat[1] = dayjs(Moment(newValue[1].$d).format("yyyy-MM-DD"));
                          displayFormat[1] = Moment(newValue[1].$d).format("yyyy-MM-DD");
                        }
                      } else if (newValue[1] === null) {
                        newFormat[1] = dayjs(Moment(newValue[0].$d).add(1, "days").format("yyyy-MM-DD"));
                        displayFormat[1] = Moment(newValue[0].$d).add(1, "days").format("yyyy-MM-DD");   
                      }
                      if(issamelastdate === true){
                        newFormat[1] = dayjs(Moment(newValue[0].$d).format("yyyy-MM-DD"));
                        displayFormat[1] = Moment(newValue[0].$d).format("yyyy-MM-DD"); 
                      }
                      setValue(newFormat);
                      setContinueText(true)
                      props.onChangeDate(displayFormat);
                    }}
                    //renderDay={renderWeekPickerDay}
                    allowSameDateSelection={false}
                    renderInput={(startProps, endProps) => (
                      <React.Fragment>
                        <TextField {...startProps} />
                        <Box sx={{ mx: 2 }}> to </Box>
                        <TextField {...endProps} />
                      </React.Fragment>
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
	       </div>
	       <div class="cartSidebarBottom">
	          <div class="cartSidebarBtn">
	            <button
	              class="customBtn customBtnDark w100"
	              onClick={() => openRoomEdit()}
	            >
	              {continueText ? t("continue"): t("roomnguest")}
	            </button>
	          </div>
	        </div>
	      </div>
      </div>
    </>
  );
};

export default DateRangeCart;
