import React, { useContext, useMemo, useState } from "react";
import { capitalizeStr, dayDiff, isTaxInclusiveInCartSingleItem } from "../../utils/utils";
import { KEYS } from "../../utils/constant";
import { LocalStorage, SessionStorage } from "../../utils/storage";
import { currencyDisplay, setTaxInclusiveInSession } from "../../utils/utils";
import getSymbolFromCurrency from "currency-symbol-map";
import AddtoCart from "./../common/Buttons/addtoCart";
import PolicyModal from "../common/Modal/policyModal";
import ViewDescription from "../room-details/viewDescription";
import ViewMoreLess from "../room-details/viewMoreLess";
import { useTranslation } from "react-i18next"
import AppContext from "../../utils/appContext";
import {SITE_ID_LIST_HASH} from "../../utils/utils"

const RateDetails = ({ activeRoom = "", rate, room, hotelData = "" }) => {
  const [showMore, setShowMore] = useState(false);
  const [openPolicy, setOpenPolicy] = useState(false)
  const { t } = useTranslation()
  const context = useContext(AppContext)
  let currentRoom = LocalStorage.get(KEYS.CURRENT_SELECTED_ROOM);
  let cartItems = JSON.parse(SessionStorage.get(KEYS.ROOM_DATA));
  let occKey = "";
  let totalPrice = 0;
  let averagePrice = 0;
  if(currentRoom){
    let singleRoom = cartItems.find(item => item.room == currentRoom)
    occKey = singleRoom.adults+ "-" + singleRoom.childrens.length + "i0";
    if (rate?.datewiserates && occKey && rate?.datewiserates?.hasOwnProperty(occKey)) {
      for (const [key, value] of Object.entries(rate?.datewiserates[occKey])) {
        totalPrice += value;
      }
    }
  }

  averagePrice = totalPrice / dayDiff();

  return (
		<div
			className={
				showMore === true
					? "column100 roomRatesInfo marginT30 arrowUp"
					: "column100 roomRatesInfo marginT30"
			}
		>
			<div className='columnWrap'>
				<div className='column100'>
					<h3 onClick={() => setShowMore(!showMore)}>
						{rate?.rate_name ? capitalizeStr(rate.rate_name).replace(/\\'/g, "'") : ""}
					</h3>

					<div className='roomPriceMobile'>
						{t(
							dayDiff() > 1
								? "total_for_multiple_night"
								: "total_for_1_night"
						).replace("[numberofnight]", dayDiff())}
						<span className='roomPrice dInlineBlock'>
							{LocalStorage.get(KEYS.SELECTED_CURRENCY) &&
							getSymbolFromCurrency(
								LocalStorage.get(KEYS.SELECTED_CURRENCY)
							)
								? getSymbolFromCurrency(
										LocalStorage.get(KEYS.SELECTED_CURRENCY)
								  )
								: LocalStorage.get(KEYS.SELECTED_CURRENCY) +
								  " "}
							{totalPrice && currencyDisplay(totalPrice)}
						</span>
						{SITE_ID_LIST_HASH.mbh?.includes(context?.configData?.site_id?.toString())?"("+t("mbh_total_price_helper_text")+")":hotelData?.showinclusivetext === 1 ? ` (${t("inclusive_of_vat_sm")})` : ` (${t("excl_taxes_and_fees")})`}
					</div>
				</div>
				<div className='column40'>
					<ViewDescription
						showMore={showMore}
						rateShortDesc={rate?.rate_short_desc}
					/>
				</div>
				<div className='column20'>
					<span className='roomPrice dBlock'>
						{LocalStorage.get(KEYS.SELECTED_CURRENCY) &&
						getSymbolFromCurrency(
							LocalStorage.get(KEYS.SELECTED_CURRENCY)
						)
							? getSymbolFromCurrency(
									LocalStorage.get(KEYS.SELECTED_CURRENCY)
							  )
							: LocalStorage.get(KEYS.SELECTED_CURRENCY)}

						{rate?.occupancies_price?.[0]?.price &&
							currencyDisplay(averagePrice)}
					</span>
					{t("average_nightly_rate")}
					{" "}
					{SITE_ID_LIST_HASH.mbh?.includes(context?.configData?.site_id?.toString())?`(${t("mbh_total_price_helper_text")})`:hotelData?.showinclusivetext === 1 ? ` (${t("inclusive_of_vat_sm")})` : ` (${t("excl_taxes_and_fees")})`}
				</div>
				<div className='column20'>
					<span className='roomPrice dBlock'>
						{LocalStorage.get(KEYS.SELECTED_CURRENCY) &&
						getSymbolFromCurrency(
							LocalStorage.get(KEYS.SELECTED_CURRENCY)
						)
							? getSymbolFromCurrency(
									LocalStorage.get(KEYS.SELECTED_CURRENCY)
							  )
							: LocalStorage.get(KEYS.SELECTED_CURRENCY)}
						{totalPrice && currencyDisplay(totalPrice)}
					</span>
					<div>{t(
						dayDiff() > 1
							? "total_for_multiple_night"
							: "total_for_1_night"
					).replace("[numberofnight]", dayDiff())}
					{isTaxInclusiveInCartSingleItem(rate) ===
						"exclusive_of_vat" && (
							` (${SITE_ID_LIST_HASH.mbh?.includes(context?.configData?.site_id?.toString())?t("mbh_total_price_helper_text"):t("excl_taxes_and_fees")})`
					)}
					</div>
				</div>
				<div className='column20'>
					<AddtoCart
						room_id={
							activeRoom ? activeRoom?.room_id : room.room_id
						}
						rate_id={rate.rate_id}
						room_name={
							activeRoom
								? activeRoom?.room_name
								: room.room_name
						}
						rate_name={rate.rate_name}
						is_tax_inclusive={setTaxInclusiveInSession(rate)}
						tax_status={isTaxInclusiveInCartSingleItem(rate)}
						price={rate.datewiserates}
						section={"room_list"}
						rate_obj={rate}
						tax_obj={rate.tax}
					/>
				</div>
				<div className='column100'>
					<div className='roomViewLinks'>
						<ViewMoreLess
							showMore={showMore}
							setShowMore={setShowMore}
							rateShortDesc={rate?.rate_short_desc}
						/>
						<a
							className='contentModal'
							href='javascript:void(0);'
							onClick={() => setOpenPolicy(true)}
							tabIndex='0'
						>
							{openPolicy && context.policyLoading
								? t("policy_loading")
								: t("view_policies")}
						</a>
					</div>
				</div>
			</div>

			<PolicyModal
				openPolicy={openPolicy}
				setOpenPolicy={setOpenPolicy}
				rateId={rate.rate_id}
				roomId={room.room_id}
			  	roomRateTaxInfo={rate?.rate_tax_info}
			  	policyCodes={rate?.policy_code_data}
			/>
		</div>
  );
};

export default RateDetails;
