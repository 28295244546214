import React, { useContext, useEffect, useState } from "react";
import Language from "./language";
import BookingSteps from "./bookingSteps";
import AppContext from "../../../../utils/appContext";
import { useLocation } from "react-router-dom";
import { route } from "../../../../utils/route";
import { SessionStorage } from "../../../../utils/storage";
import { KEYS } from "../../../../utils/constant";
import WithHeader from "./withHeader";
import { useTranslation } from "react-i18next";
import backarrow from "../../../../styles/images/back-arrow.png";
import SeoTags from "../../Seo";
import Favicon from "../../Favicon";

const HeaderIndex = ({ currencyList, siteConfig, authToken }) => {
	const { t } = useTranslation();
	let location = useLocation();
	let context = useContext(AppContext);
	useEffect(() => {
		window.addEventListener("scroll", isSticky);
		return () => {
			window.removeEventListener("scroll", isSticky);
		};
	});

	const isInView = (el) => {
		let box = el?.getBoundingClientRect();
		return box?.top <= window?.innerHeight && box?.bottom >= 0;
	};

	// define where currency/language popup icon needs to shown
	const showLanguageIcon = () => {
		if (location.pathname === route.roomList || location.pathname === route.roomDetail || (location.pathname.split("/")[1] === route.roomListIndex.split("/")[1])) {
			return true;
		} else {
			return false;
		}
	};

	// If error trigger and catch by error boundary
	const isErrorTriggered = () => {
		if (context.isErrorTriggered === true) {
			return true;
		} else {
			return false;
		}
	};

	/* Method that will fix header after a specific scrollable */
	const isSticky = (e) => {
		const header = document.querySelector(".mainHeader");
		const mainContent = document.querySelector(".mainContent");
		// const roomDetailSidebarWrap = document.querySelector(".roomDetailSidebarWrap");
		const scrollTop = window.scrollY;

		if (scrollTop >= 0) {
			header?.classList?.add("stickyHeader");
			mainContent?.classList?.add("mainContentTopGap");
			// if (location.pathname === route.roomDetail) {
			//   roomDetailSidebarWrap.classList.add("roomDetailSidebarWrapSticky");
			// }
		} else {
			//header?.classList?.remove("stickyHeader");
			// mainContent?.classList?.remove("mainContentTopGap");
			// if (location.pathname === route.roomDetail) {
			//   roomDetailSidebarWrap.classList.remove("roomDetailSidebarWrapSticky");
			// }
		}

		var footer = document.getElementById("mainFooter");
		var mainCart = document.getElementById("mainCart");
		var mainContDiv = document.getElementsByClassName("mainContent");
		mainContDiv = mainContDiv[0];
		var mainCartDetailWrap = document.getElementById("mainCartDetailWrap");
		if (mainCart != null && mainCart != "") {
			var visible = isInView(footer);
			if (visible) {
				mainCart.classList.remove("stickySidebar");
				mainContDiv.classList.add("stickySidebarFooter");
			} else {
				mainCart.classList.add("stickySidebar");
				mainContDiv.classList.remove("stickySidebarFooter");
			}

			
		}
		if (mainCartDetailWrap != null && mainCartDetailWrap != "") {
			var visible = isInView(footer);
			if (visible) {
				mainCartDetailWrap.classList.remove(
					"roomDetailSidebarWrapSticky"
				);
				document.getElementById("cartEditDate").focus();
			} else {
				mainCartDetailWrap.classList.add("roomDetailSidebarWrapSticky");
			}
		}
	};

	let mainCartinitial = document.getElementById("mainCart");
	if (mainCartinitial != null && mainCartinitial != "") {
		let mainContDivinit = document.getElementsByClassName("mainContent");
		mainContDivinit = mainContDivinit[0];
		mainCartinitial.classList.add("stickySidebar");

		let mainContentinit = document.querySelector(".mainContent");
		mainContentinit?.classList?.add("mainContentTopGap");

		mainContDivinit.classList.remove("stickySidebarFooter");
	}

	let diffHeaderClass =
		location.pathname === route.manageBookings ||
		location.pathname === route.cancelConfirmation ||
		location.pathname === route.errorPage ||
		location.pathname === route.fourZeroFour ||
		location.pathname === route.maintenance ||
		isErrorTriggered() === true; // If error triggered and 'Error Fallback' component called, Then use diff header

	useEffect(() => {
		if (SessionStorage.getJSON(KEYS.IS_RESERVATION) && SessionStorage.get("is_modification")) {
			context.setIsBookingData(true);
		}
	}, []);

	const goBack = () => {
		context.setIsBookingData(false);
		const element = document.getElementById("viewReservationPageId");
		element.scrollIntoView({ behavior: "smooth" });
		SessionStorage.remove(KEYS.IS_RESERVATION);
		SessionStorage.remove("is_modification")
	};

	let currentPage = location.pathname.replace(/\//g, ""); // remove forward slash ('/')
	let siteId = siteConfig?.site_id;

	function bodyToggleClass(){
		var bodyElement = document.body;
		bodyElement.classList.toggle('bodyHiddenMobile');
	}

	return (
		<>
			<SeoTags authToken={authToken} currentPage={currentPage} />
			<Favicon siteId={siteId} />
			{location.pathname === route.declineBooking ||
			location.pathname === route.cancelConfirmation ? (
				<header className='mainHeader paddingTB15 mainHeaderThird'>
					<div className='columnContainerFull'>
						<div className='columnWrap alignItemsCenter justifyContentCenter'>
							<WithHeader siteConfig={siteConfig} />
						</div>
					</div>
				</header>
			) : diffHeaderClass ? (
				<header className='mainHeader paddingTB15 mainHeaderSecond '>
					<div className='columnContainerFull'>
						<div className='columnWrap alignItemsCenter justifyContentCenter'>
							<div className='headerBackTo'>
								<div className='backToAllRoom'>
									{context.isBookingData ? (
										<a
											className='dInlineBlock textUppercase'
											href='javascript:void(0);'
											onClick={() => goBack()}
											tabindex="0"
										>
											{/* <i className='fa fa-angle-left'></i> */}
											<img
												src={backarrow}
												alt='Back Arrow'
											/>
											{t("back")}
										</a>
									) : (
										""
									)}
								</div>
							</div>
							<WithHeader
								className='column70 textCenter'
								siteConfig={siteConfig}
							/>
							{showLanguageIcon() &&
								isErrorTriggered() !== true && (
									<Language currencyList={currencyList} />
								)}
						</div>
					</div>
				</header>
			) : (
				<header className='mainHeader paddingTB15 stickyHeader'>
					<div className='columnContainerFull'>
						<div className='columnWrap alignItemsCenter'>
							<WithHeader siteConfig={siteConfig} />
							<BookingSteps />
							{showLanguageIcon() && (
								<Language currencyList={currencyList} />
							)}
						</div>
						<div
							className='viewStayBtn textUppercase posRelative'
							onClick={() =>{
								context.setYourStay(!context.yourStay);
								bodyToggleClass();
							}}
						>
							{t("view_your_stay")}
						</div>
					</div>
				</header>
			)}
		</>
	);
};

export default HeaderIndex;
