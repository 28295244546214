import React from 'react'

const ViewDescription = ({
    showMore,
    rateShortDesc
}) => {

  const getDescription = () => {
    let result = "";
    const dynamicTagRegex = /<([a-z]+)[^>]*>/i;
    const closingTagRegex = /<\/[a-z]+>/i;
    const match = dynamicTagRegex.exec(rateShortDesc);
    if (showMore || rateShortDesc?.length <= 50) {
      result = rateShortDesc
    } else if (match) {
      const startIndex = match.index;
      let endIndex = startIndex;
      let lineCount = 0;
      while (lineCount < 2) {
        const closingTagMatch = closingTagRegex.exec(rateShortDesc?.substring(endIndex));
        if (closingTagMatch) {
          endIndex += closingTagMatch.index + closingTagMatch[0].length;
        } else {
          endIndex = rateShortDesc?.indexOf('\n', endIndex);
          if (endIndex === -1) {
            endIndex = rateShortDesc?.length;
          }
        }
        lineCount++;
      }
      result = rateShortDesc?.substring(startIndex, endIndex);
    } else if (rateShortDesc?.length > 50 && !showMore) {
      result = `${rateShortDesc?.substring(0, 50)}...`
    }
    return result;
  };

  return (
    //<ul className='listStyled'>
      <div dangerouslySetInnerHTML={{__html: getDescription()}} />
    //</ul>
  )
}

export default ViewDescription