import React, { useContext, useEffect, useState } from 'react'
import DateRangeCart from './dateRangeCart'
import BeCart from './beCart'
import RoomSelection from './roomSelection'
import AppContext from '../../utils/appContext'
import AddCodes from './addCodes'
import Loader from '../common/Loader'
import { useLocation } from "react-router-dom";
import { route } from "../../utils/route";
import { SessionStorage } from '../../utils/storage'
import { KEYS } from '../../utils/constant'

const Cart = (props) => {
    const context = useContext(AppContext)
    const location = useLocation();
    let checkindatevar = SessionStorage.get(KEYS.CHECK_IN_DATE) || '';
    let checkoutdatevar = SessionStorage.get(KEYS.CHECKOUT_DATE) || '';
    const [viewCalendar, setViewCalendar] = useState(0);
    const [showRoomCount, setShowRoomCount] = useState(0);
    const [showSection, setshowSection] = useState('cart');
    const [viewEditRoom, setviewEditRoom] = useState(0);
    const [viewCode, setviewCode] = useState(0);
    const [editroomno, seteditroomno] = useState(1);
    const [showSingleRoom, setshowSingleRoom] = useState(true);
    const [validBookingCode, setValidBookingCode] = useState(false);
    const [checkindate, setcheckindate] = useState(checkindatevar);
    const [checkoutdate, setcheckoutdate] = useState(checkoutdatevar);

    useEffect(() => {
        if (checkindatevar && checkoutdatevar) {
            setcheckindate(checkindatevar)
            setcheckoutdate(checkoutdatevar)
        }
     }, [checkindatevar, checkoutdatevar])

    useEffect(() => {
        if (context.hotelData !== undefined && context.hotelData.valid_code === false && location.pathname !== route.checkout && location.pathname !== route.roomDetail) {
            openCodeSection()
        }
    }, []);

    const openCalendar = () => {
        setViewCalendar(viewCalendar === 0 ? 1 : 0)
        setshowSection(viewCalendar === 0 ? 'calendar' : 'cart')
        setcheckindate(checkindatevar)
        setcheckoutdate(checkoutdatevar)
    }

    const openRoomEdit = (roomno) => {
        seteditroomno(roomno);
        setviewEditRoom(viewEditRoom === 0 ? 1 : 0)
        setshowSection(viewEditRoom === 0 ? 'editroom' : 'cart')
        if (viewEditRoom) {
            setcheckindate(checkindatevar)
            setcheckoutdate(checkoutdatevar)
        }
    }

    const openCodeSection = () => {
        setviewCode(viewCode === 0 ? 1 : 0);
        setshowSection(viewCode === 0 ? 'addcode' : 'cart');
    }

    const showRoomCountFunc = () => {
        setShowRoomCount(1);
    }

    const hideRoomCountFunc = () => {
        setShowRoomCount(0);
    }

    return (
        <>
            {props.loading && <Loader />}
            <aside className={`column30 cartSidebarWrap pR0 stickySidebar ${context.yourStay ? "cartToggle" : ""}`} id="mainCart">
                {
                    {
                        'cart': <BeCart
                            onSubmitCheckout={props?.onSubmitCheckout}
                            checkindate={checkindate}
                            checkoutdate={checkoutdate}
                            hotelData={props.hotelData}
                            checkoutData={props.checkoutData}
                            siteConfig={props.siteConfig}
                            getRoomList={props.getRoomList}
                            openCalendar={openCalendar}
                            openRoomEdit={openRoomEdit}
                            hideRoomCountFunc={hideRoomCountFunc}
                            openCodeSection={openCodeSection}
                            setshowSingleRoom={setshowSingleRoom}
                            setValidBookingCode={setValidBookingCode}
                        />,
                        'calendar': <DateRangeCart
                            checkindate={checkindate}
                            checkoutdate={checkoutdate}
                            openCalendar={openCalendar}
                            setshowSingleRoom={setshowSingleRoom}
                            openRoomEdit={openRoomEdit}
                            showRoomCountFunc={showRoomCountFunc}
                            onChangeDate={(fromDate) => {
                                setcheckindate(fromDate[0]);
                                setcheckoutdate(fromDate[1]);
                            }}
                        />,
                        'editroom': <RoomSelection
                            showRoom={showRoomCount}
                            showsingleroom={showSingleRoom}
                            roomnumber={editroomno}
                            getRoomList={props.getRoomList}
                            siteConfig={props.siteConfig}
                            openRoomEdit={openRoomEdit}
                            checkindate={checkindate}
                            checkoutdate={checkoutdate}
                        />,
                        'addcode': <AddCodes
                            showRoom={showRoomCount}
                            showsingleroom={true}
                            viewCode={viewCode}
                            validBookingCode={validBookingCode}
                            getRoomList={props.getRoomList}
                            setValidBookingCode={setValidBookingCode}
                            openCodeSection={openCodeSection}
                        />
                    }[showSection]
                }
            </aside>
        </>
    )
}

export default Cart